import '@radix-ui/themes/styles.css';
import { Theme,Tabs, Box} from '@radix-ui/themes';
import Domains from './Domains'
import PurchaseDomains from './PurchaseDomains'
import Records from './Records'


function App() {
  return (
    <html>
    <body>
      <Theme appearance='dark'>
        <Tabs.Root defaultValue="current_domains">
          <Tabs.List>
            <Tabs.Trigger value="current_domains">Domains</Tabs.Trigger>
            <Tabs.Trigger value="records">Records</Tabs.Trigger>
            <Tabs.Trigger value="purchase_domains">Purchase Domains</Tabs.Trigger>
          </Tabs.List>

          <Box pt="3">
            <Tabs.Content value="current_domains">
              <Domains/>
            </Tabs.Content>

            <Tabs.Content value="purchase_domains">
              <PurchaseDomains/>
            </Tabs.Content>

            <Tabs.Content value="records">
              <Records/>
            </Tabs.Content>
          </Box>
        </Tabs.Root>
      </Theme>
    </body>
  </html>
  );
}

export default App;
