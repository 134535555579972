import '@radix-ui/themes/styles.css';
import { MagnifyingGlassIcon, InfoCircledIcon } from '@radix-ui/react-icons'
import React, { Component } from 'react';
import axios from 'axios'
import { Dialog, Table,Container,TextField, Button, Flex, CheckboxCards, Box,Grid, Text, Callout, ScrollArea  } from '@radix-ui/themes';
import CONFIG from './config';
class PurchaseDomains extends Component {

  state = { domains: [], searchDomain: "", todoList : [], resTodoList : [], callout : "", selectDomain : null,  open:false };

  handleSearchDomainChange = (e) => {
    this.setState({
      searchDomain: e.target.value
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    var selectDomain = this.state.selectDomain
    selectDomain[name] = value
    this.setState({
      selectDomain : selectDomain
    })
  };


  setModal(open) {
    this.setState({
      open: open
    });
  }

  handleSelectDomain = (domain,multiple) => {
    this.setState({
      selectDomain: domain,
      multiple: multiple
    })
    this.setModal(true)
  };

  handleAllDomain = (domains) => {
    domains.forEach((domain) => {
      if (domain["name"].includes("-news.fr")) {
        domain["mailProvider"] = "sendgrid"
      } else {
        domain["mailProvider"] = "workspace"
      }
      axios({
        method: 'POST',
        url:  CONFIG["DOMAIN"] + '/domains',
        data : {
          "zone" : domain["name"],
          "price": domain["price"],
          "expirationDate" : new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
          "mailProvider" : domain["mailProvider"],
          "projectId" : this.state.selectDomain["projectId"]
        }
      }).then((response) =>  {
        this.setState({callout: "Successfull add " + domain["name"]})
        this.setModal(false)
        setTimeout(() => {
          this.setState({callout: ""})
        }, 10000);
      });
    })
  };


  handleAddDomain = (domain) => {
    axios({
      method: 'POST',
      url:  CONFIG["DOMAIN"] + '/domains',
      data : {
        "zone" : domain["name"],
        "price": domain["price"],
        "expirationDate" : new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        "mailProvider" : domain["mailProvider"],
        "projectId" : domain["projectId"]
      }
    }).then((response) =>  {
      this.setState({callout: "Successfull add " + domain["name"]})
      this.setModal(false)
      setTimeout(() => {
        this.setState({callout: ""})
      }, 10000);
    });
  };

  myLoad = (force) => {
    axios({
      method: 'GET',
      url:  CONFIG["DOMAIN"] + '/todolist',
    }).then((response) =>  {
      this.setState({
        todoList: response.data
      });
      if (this.state.resTodoList.length === 0 || force)
      {
        this.setState({
          resTodoList: response.data
        });
      }
    });
  }

  componentDidMount() {
    this.myLoad(false)

  }

  handleSearchTodolistChange = (e) => {
    if (e.target.value !== "") {
      this.setState({resTodoList: this.state.todoList.filter(todoList => todoList["name"].includes(e.target.value) )});
    } else {
      this.setState({resTodoList: this.state.todoList})
    }
  };

  handleClickTodoList = (name) => {
    axios({
      method: 'DELETE',
      url:  CONFIG["DOMAIN"] + '/todolist/'+name,
    }).then((response) =>  {
      this.myLoad(true)

    });
  };

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      axios({
        method: 'GET',
        url:  CONFIG["DOMAIN"] + '/ovh/domains/' + this.state.searchDomain,
      }).then((response) =>  {
        this.setState({
          domains: response.data
        });
      });
    }
  };


  render() {
    return (
        <div>
        <Container>
          <Grid rows="2" gap='9'>
            <Grid columns="0.7fr 0.3fr"  gap='5'>
              <Box>
                <TextField.Root placeholder="Search domains..." onChange={this.handleSearchDomainChange}  onKeyDown={this._handleKeyDown}>
                  <TextField.Slot>
                    <MagnifyingGlassIcon height="16" width="16" />
                  </TextField.Slot>
                </TextField.Root>
                <Table.Root>
                  <Table.Header>
                    <Table.Row>
                      <Table.ColumnHeaderCell>Domain</Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>Prix Annuel</Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>Action</Table.ColumnHeaderCell>
                    </Table.Row>
                  </Table.Header>
          
                  <Table.Body>

                  {this.state.domains.map(domain => {
                    return (
                      <Table.Row>
                        <Table.RowHeaderCell>{domain["name"]}</Table.RowHeaderCell>
                        <Table.Cell>{domain["price"]}</Table.Cell>
                        <Table.Cell>
                          <Button color="orange" onClick={() => {this.handleSelectDomain(domain,false)} } disabled={!domain["availability"]}>
                            Ajouter
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}

                    <Table.Row hidden={this.state.domains.length == 0 || !this.state.domains.every((item) => item["availability"] === true)}>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell>
                          <Button color="orange" onClick={() => {this.handleSelectDomain({},true)} }>
                            Purshase All
                          </Button>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table.Root>
              </Box>

              <Box>
                <Flex direction="column" gap="3">
                <TextField.Root placeholder="Search todolist name..." onChange={this.handleSearchTodolistChange}  onKeyDown={this._handleKeyDown}>
                  <TextField.Slot>
                    <MagnifyingGlassIcon height="16" width="16" />
                  </TextField.Slot>
                </TextField.Root>
                <ScrollArea type="always" scrollbars="vertical"  style={{ height: 400 }}>
                  <Flex direction="column" gap="3">
                    {this.state.resTodoList.map(todoList => {
                        return (
                          <CheckboxCards.Root defaultValue={['1']} variant="surface">
                          <CheckboxCards.Item value="0" onClick={() => {this.handleClickTodoList(todoList["name"])}}>
                            <Flex direction="column" width="100%">
                              <Text weight="bold">{todoList["name"]}</Text>
                              <Text>{todoList["description"]}</Text>
                            </Flex>
                          </CheckboxCards.Item>
                        </CheckboxCards.Root>
                        );
                    })}
                  </Flex>
                </ScrollArea>
                </Flex>
              </Box>
            </Grid>
            <Flex direction="column" gap="1">
              { this.state.callout !== "" &&
                <Callout.Root  >
                <Callout.Icon>
                  <InfoCircledIcon />
                </Callout.Icon>
                <Callout.Text>
                    { this.state.callout }
                </Callout.Text>
              </Callout.Root> }
            </Flex>
          </Grid>

          
          <Dialog.Root open={this.state.open} >
              <Dialog.Content>
              <Dialog.Title className="DialogTitle">Etes vous sur de vouloir acheter ce domain {this.state.selectDomain != null ? this.state.selectDomain["name"] : ""} ?</Dialog.Title>
              <Dialog.Description className="DialogDescription">
              <Box maxWidth="200px">
                <TextField.Root size="1" name="projectId" onChange={this.handleChange} placeholder="Project id" />
              </Box>
              <Box maxWidth="200px">
                <TextField.Root size="1" name="mailProvider" onChange={this.handleChange} placeholder="Mail Provider" />
              </Box>
              </Dialog.Description>
                <Button color="orange" onClick={() => {this.setModal(false)}}>
                  Cancel
                </Button>
                <Button color="orange" onClick={() => {this.state.multiple  ?  this.handleAllDomain(this.state.domains) : this.handleAddDomain(this.state.selectDomain)}}>
                  Ajouter
                </Button>
              </Dialog.Content>
          </Dialog.Root>
        </Container>

        </div>
    );
  }
}

export default PurchaseDomains;
